import React from "react";

// Components
import ProblemList from "../ProblemList";
import Typography from "@material-ui/core/Typography";

// Types
import { Problem } from "../Problem";

const problems: ReadonlyArray<Problem> = [
  {
    id: 1,
    imageFilename: "complexity.png",
    title: "Complexité",
    description:
      "La diffusion et la récupération de données à partir de sources multiples restent complexes",
  },
  {
    id: 2,
    imageFilename: "error.png",
    title: "Risques d'erreurs",
    description:
      "Erreurs de saisie, mises à jour tardives des données sont sources de dysfonctionnements",
    imageStyles: {
      width: 200,
    },
  },
  {
    id: 3,
    imageFilename: "stopwatch.png",
    title: "Perte de temps",
    description:
      "Toute action de collecte, de mise à jour ou de diffusion de données et de documents est chronophage",
    imageStyles: {
      width: 80,
    },
  },
] as const;

const intro = (
  <Typography variant="h5" align="center">
    La collecte et la diffusion de données et documents restent problématiques
    et source de coûts financiers certains
  </Typography>
);

const Problematics = () => {
  return <ProblemList introduction={intro} problems={problems} />;
};

export default Problematics;
