import React from "react";

// Styles
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fillGrey: {
    fill: theme.convexSvg.grey,
  },
  backgroundGrey: {
    background: theme.convexSvg.grey,
  },
  fillWhite: {
    fill: theme.convexSvg.white,
  },
  backgroundWhite: {
    background: theme.convexSvg.white,
  },
}));

export type ConvexSvgProps = {
  variant: "bottom" | "top";
  color: "grey" | "white";
} & React.SVGProps<SVGSVGElement>;

const ConvexSvg = ({
  variant,
  color,
  className,
  ...svgProps
}: ConvexSvgProps): JSX.Element => {
  const classes = useStyles();

  const doFillWhite = variant === "top" ? color === "grey" : color === "white";

  const classString = clsx(className, {
    [classes.backgroundGrey]: doFillWhite,
    [classes.backgroundWhite]: !doFillWhite,
    [classes.fillWhite]: doFillWhite,
    [classes.fillGrey]: !doFillWhite,
  });

  if (variant === "top") {
    return (
      <svg
        viewBox="0 0 1440 48"
        version="1.1"
        className={classString}
        {...svgProps}
      >
        <g transform="translate(0.000000, -436.000000)">
          <g>
            <path d="M1440,484 C1225.09668,452 985.09668,436 720,436 C627.991932,436 539.007013,437.927362 453.045242,441.782085 C345.235831,446.616507 194.22075,460.689145 0,484 L0,436 L1440,436 L1440,484 Z"></path>
          </g>
        </g>
      </svg>
    );
  }

  return (
    <svg
      viewBox="0 0 1440 67"
      version="1.1"
      className={classString}
      {...svgProps}
    >
      <g transform="translate(0.000000, -1365.000000)">
        <g transform="translate(0.000000, 783.000000)">
          <g>
            <path d="M0,582 C194.22075,605.310855 345.235831,619.383493 453.045242,624.217915 C539.007013,628.072638 627.991932,630 720,630 C985.09668,630 1225.09668,614 1440,582 L0,582 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ConvexSvg;
