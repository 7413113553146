import React, { ReactElement } from "react";

// Components
import Bar from "./bar";
import Grid from "@material-ui/core/Grid";
import ProblemItem, { Problem } from "./Problem";

// Styles
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";

// Types
import { StandardProps } from "@material-ui/core";

const styles = createStyles((theme: Theme) => ({
  introGrid: {
    margin: "auto",
    marginBottom: theme.spacing(2),
  },
  listGrid: {},
}));

export type ProblemListClassKey = "introGrid" | "listGrid";
export type ProblemListProps = StandardProps<
  {
    /** Problems to render */
    problems: ReadonlyArray<Problem>;

    /** Introduction component rendered on top of the list  */
    introduction: ReactElement;
  },
  ProblemListClassKey
>;

const ProblemList = ({ problems, introduction, classes }: ProblemListProps) => {
  return (
    <>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        className={classes?.introGrid}
      >
        <Grid item md={7} sm={9} xs={12}>
          {introduction}
        </Grid>
      </Grid>
      <Grid container spacing={3} className={classes?.listGrid}>
        {problems.map((problem) => (
          <Grid item xs={12} sm={4} key={problem.id}>
            <ProblemItem problem={problem} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default withStyles(styles)(ProblemList);
