import React from "react";

// Components
import Bar from "../bar";
import Services from "../Services";
import Typography from "@material-ui/core/Typography";

const OurSolution = (): JSX.Element => (
  <>
    <Typography align="center" variant="h4" gutterBottom>
      Notre solution
    </Typography>
    <Bar variant="middle" style={{ margin: "auto" }} />

    <Typography align="center" variant="h6" style={{ margin: 24 }}>
      Une plateforme de gestion et de partage de données qui simplifie la vie de
      tous les jours. Simple, autonome et accessible à tous pour des bénéfices
      immédiats.
    </Typography>
    <Services />
  </>
);

export default OurSolution;
