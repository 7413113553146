import React from "react";

// Components
import Card, { CardProps } from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Image from "./image";
import Typography from "@material-ui/core/Typography";
import { Link } from "gatsby";

// Styles
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

export type ServiceCardProps = {
  /** Service to render */
  service: Service;
} & CardProps;

export type Service = {
  /** Unique ID of the service  */
  id: string | number;

  /** Name of the file used as the image */
  imageFilename: string;

  /** Title of the card. Also used as the `alt` of the image */
  title: string;

  /** Description of the card */
  description: React.ReactNode;

  /** Link destination */
  to: string;

  /** Optional styles to apply to the image */
  imageStyles?: React.CSSProperties;
};

const useStyles = makeStyles({
  actionArea: {
    borderRadius: 16,
    transition: "0.2s",
    height: "100%",
    "&:hover": {
      transform: "scale(1.02)",
    },
  },
  card: {
    borderRadius: 16,
    height: "100%",
  },
});

const ServiceCard = ({ service, className, ...props }: ServiceCardProps) => {
  const classes = useStyles();
  return (
    <CardActionArea
      component={Link}
      className={classes.actionArea}
      to={service.to}
    >
      <Card className={clsx(className, classes.card)} {...props}>
        <CardContent style={{ textAlign: "start", minHeight: 200 }}>
          <div
            style={{
              width: 80,
              height: 80,
              padding: 10,
              ...service.imageStyles,
            }}
          >
            <Image alt={service.title} filename={service.imageFilename} />
          </div>
          <Typography variant="h6" color="inherit">
            {service.title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {service.description}
          </Typography>
        </CardContent>
      </Card>
    </CardActionArea>
  );
};

export default ServiceCard;
