import React from "react";

// Gatsby
import { Link } from "gatsby";

// Components
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Image from "./image";
import Hidden from "@material-ui/core/Hidden";

import { makeStyles } from "@material-ui//core/styles";

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: "flex",
    width: "100%",

    "& > *": {
      marginRight: theme.spacing(1),
    },
  },
}));

const Hero = (): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid container spacing={3} style={{ minHeight: 500 }}>
      <Grid
        item={true}
        xs={12}
        sm={6}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        style={{ paddingLeft: 40, paddingRight: 40 }}
      >
        <Typography variant="h4" color="inherit" style={{ marginBottom: 20 }}>
          Simplifiez et fiabilisez vos échanges de données
        </Typography>

        <Typography variant="subtitle1" style={{ marginBottom: 30 }}>
          Avec la plateforme sécurisée eKee, collectez et diffusez
          automatiquement données et documents auprès de vos contacts pour
          disposer d'informations fiables et à jour en permanence.
        </Typography>
        <div className={classes.buttons}>
          <Button
            component={Link}
            to="/enterprise"
            variant="contained"
            color="primary"
          >
            Pour l'entreprise
          </Button>
          <Button
            component={Link}
            to="/individual"
            variant="contained"
            color="primary"
          >
            Pour le particulier
          </Button>
        </div>
      </Grid>
      <Hidden xsDown>
        <Grid
          item={true}
          sm={6}
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
          style={{ padding: 10 }}
        >
          <div style={{ width: "100%" }}>
            <Image alt="Votre identité numérique" filename="homepage.png" />
          </div>
        </Grid>
      </Hidden>
    </Grid>
  );
};
export default Hero;
