import React from "react";

// Components
import Panel, { IPanel } from "./Panel";

const panels: ReadonlyArray<IPanel> = [
  {
    id: 1,
    imageFilename: "communication-referential.png",
    title: "Un référentiel de données",
    subtitle: "Pour parler le même langage",
    body:
      "Le référentiel de données eKee permet d'échanger les données essentielles à la gestion de toute relation.",
    learn_more_link: "/blog/referentiel-de-donnees",
  },
  {
    id: 2,
    imageFilename: "database.png",
    title: "Une base de données",
    subtitle: "Pour gérer vos données simplement",
    learn_more_link: "/blog/base-de-donnees",
    body: (
      <>
        Gérez vos propres données et documents pour une diffusion maîtrisée et
        automatique.
        <br />
        Accédez aux données et documents à jour de vos contacts pour les
        intégrer simplement.
      </>
    ),
  },
  {
    id: 3,
    imageFilename: "api-panel.png",
    title: "Une API puissante",
    subtitle: "Pour connecter votre Système d'Information",
    learn_more_link: "/blog/api",
    body:
      "Automatisez, quand vous le souhaitez, la mise à jour de vos logiciels (CRM, ERP, SIRH, GED, ...) pour fiabiliser et mieux rentabiliser leur exploitation.",
  },
  {
    id: 4,
    imageFilename: "ekee-rocket.png",
    title: "Une technologie plus qu'une application",
    subtitle: "Le moteur eKee à votre service",
    learn_more_link: "/blog/ekee-engine",
    body:
      "Exploitez l'engine eKee pour générer vos propres API, pour exécuter des actions multiples automatiquement (callback, webhooks, ...) afin d'augmenter les performances de votre Système d'Information.",
  },
];

const Panels = (): JSX.Element => (
  <>
    {panels.map((p, idx) => (
      <Panel key={p.id} panel={p} imageRight={idx % 2 === 1} />
    ))}
  </>
);

export default Panels;
