import React from "react";

// Components
import Container from "@material-ui/core/Container";
import ConvexSvg from "../components/ConvexSvg";
import SEO from "../components/SEO";
import Hero from "../components/hero";
import Problematics from "../components/WelcomePage/Problematics";
import OurSolution from "../components/WelcomePage/OurSolution";
import Panels from "../components/Panels";
import Summaries from "../components/Summaries";

// Styles
import { makeStyles } from "@material-ui/core/styles";

const styles = makeStyles((theme) => ({
  main: {},
  paddingBot: {
    paddingBottom: theme.spacing(10),
  },
  section1: {
    background: theme.gradients.gradientWhiteGrey,

    paddingBottom: theme.spacing(12),
  },
  section2: {
    paddingBottom: theme.spacing(12),
  },
  section3: {
    background: theme.gradients.gradientGreyWhite,
  },
  section4: {
    background: theme.gradients.gradientGreyWhite,
    paddingBottom: theme.spacing(6),
  },
}));

const IndexPage = (): JSX.Element => {
  const classes = styles();

  return (
    <>
      <SEO title="eKee" />
      <main className={classes.main}>
        <section className={classes.section1}>
          <Container maxWidth="md">
            <Hero />
            <Problematics />
          </Container>
        </section>
        <section className={classes.section2}>
          <ConvexSvg
            variant="bottom"
            color="grey"
            className={classes.paddingBot}
          />
          <Container maxWidth="md">
            <OurSolution />
          </Container>
        </section>
        <section className={classes.section3}>
          <ConvexSvg variant="top" color="grey" />
          <Container maxWidth="md">
            <Panels />
          </Container>
        </section>
        <section className={classes.section4}>
          <ConvexSvg
            variant="bottom"
            color="white"
            className={classes.paddingBot}
          />
          <Container maxWidth="lg">
            <Summaries />
          </Container>
        </section>
      </main>
    </>
  );
};

export default IndexPage;
