import React from "react";

// Components
import Grid, { GridProps } from "@material-ui/core/Grid";
import ServiceCard, { Service } from "./service-card";

const services: ReadonlyArray<Service> = [
  {
    id: 1,
    imageFilename: "search-doctor-icon.png",
    title: "Gestion de contacts",
    to: "/blog/gestion-de-contacts",
    description:
      "Disposez en permanence des données à jour de vos contacts, personnes physiques ou entreprises",
  },
  {
    id: 2,
    imageFilename: "document-icon.png",
    title: "Gestion de documents",
    to: "/blog/gestion-de-documents",
    description: (
      <>
        Une Gestion Électronique de Documents parfaitement intégrée.
        <br />
        Récupérez et classez efficacement vos documents et ceux issus de vos
        contacts
      </>
    ),
  },
  {
    id: 3,
    imageFilename: "handshake-icon.png",
    title: "Gestion des consentements",
    to: "/blog/gestion-de-consentements",
    description: (
      <>
        Gérez la diffusion automatique à vos contacts de vos données et
        documents à jour.
        <br />
        Maîtrisez les accès accordés par vos contacts à leurs données et
        documents à jour.
      </>
    ),
  },
  {
    id: 4,
    imageFilename: "legal-icon.png",
    title: "Accès aux bases RNCS-SIRENE-BODACC",
    to: "/blog/bases-rncs-sirene-bodacc",
    description: (
      <>
        Pour une mise à jour automatique de vos bases clients/fournisseurs.
        <br />
        Pour une veille juridique permanente. <br />
        Pour accéder à tous les actes et comptes publiés.
      </>
    ),
  },
  {
    id: 5,
    imageFilename: "notifs-icon.png",
    title: "Système de notifications",
    to: "/blog/systeme-de-notification",
    description: (
      <>
        Soyez informé en permanence des mises à jour de vos contacts, des
        documents associés et de l'évolution juridique de vos partenaires.
      </>
    ),
    imageStyles: { width: 140 },
  },
  {
    id: 6,
    imageFilename: "rgpd-icon.png",
    title: "RGPD Natif",
    to: "/blog/rgpd-natif",
    description: (
      <>
        Consentements - Information - Portabilité
        <br />
        eKee apporte une solution simple de gestion du RGPD et le transforme en
        opportunité.
      </>
    ),
  },
] as const;

const Services = (props: GridProps): JSX.Element => {
  return (
    <Grid
      container
      spacing={3}
      style={{
        textAlign: "center",
        minHeight: 200,
        padding: 10,
      }}
      {...props}
    >
      {services.map((service) => (
        <Grid item xs={12} sm={4} key={service.id}>
          <ServiceCard service={service} style={{ height: "100%" }} />
        </Grid>
      ))}
    </Grid>
  );
};
export default Services;
