import React from "react";

// Components
import Bar from "./bar";
import Summary, { ISummary } from "./Summary";
import Typography from "@material-ui/core/Typography";

// Styles
import { makeStyles } from "@material-ui/core/styles";
import { blue, grey } from "@material-ui/core/colors";

const summaries: ReadonlyArray<ISummary> = [
  {
    title: "B to B",
    subtitle: "La transformation numérique accessible à tous",
    points: [
      "Pour toutes les relations entre tout type d'organisations : entreprises, fédérations professionnelles, associations, administrations, collectivités, ...",
      "eKee permet de supprimer les échanges de mails et les ressaisies multiples.",
      "eKee automatise les échanges de données et documents essentiels à l'entrée en relation et au maintien des relations entre organisations.",
      "Les documents c'est bien, avec les données c'est mieux ! ",
    ],
  },
  {
    title: "B to C",
    subtitle: "Le RGPD transformé en opportunité",
    points: [
      "Avec vos salariés et clients personnes physiques, eKee permet de répondre à la mise en oeuvre de véritables consentements éclairés et explicites.",
      'eKee offre des consentements "augmentés" qui permettent non seulement la collecte, mais en plus l\'accès aux mises à jour des données collectées initialement.',
      "eKee crée une nouvelle relation avec vos clients en permettant de mettre directement à leur disposition les données et les documents qui les concernent.",
      "Droit à l'information, droit à la portabilité, rien de plus simple avec eKee !",
    ],
  },
  {
    title: "C to C",
    subtitle: "Une solution professionnelle pour les particuliers",
    points: [
      "eKee apporte une solution de Système de Gestion de Données Personnelles évolutif et communicant.",
      "Gérer ses données personnelles et ses documents très simplement.",
      "Disposer des coordonnées complètes et toujours à jour de ses contacts sans effort.",
      "Récupérer et centraliser dans eKee ses données et ses documents en relation avec des tiers sans avoir à se connecter sur de multiples sites.",
      "Toutes vos données servies à domicile !",
    ],
  },
];

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: theme.spacing(3),
  },
  headerIntro: {
    textTransform: "uppercase",
    fontWeight: "bold",
    lineHeight: 1.6,
    marginBottom: theme.spacing(0.5),
    maxWidth: 600,
  },
  headerDesc: {
    maxWidth: 600,
  },
  summaries: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",

    textAlign: "center",
    marginBottom: theme.spacing(3),

    // Titles of the summaries
    "& h5": {
      fontWeight: "bold",
    },

    "& h6": {
      padding: `0 ${theme.spacing(1)}px`,
    },

    // Between each summary
    "& > *": {
      flex: "0 1 352px",
      margin: theme.spacing(2),
      paddingTop: theme.spacing(2),
    },

    // For each point
    "& .MuiCard-root p": {
      padding: theme.spacing(2),
    },

    // Last child of the each summary
    "& li:last-child": {
      paddingBottom: 0,
    },

    // Center summary
    "& .MuiCard-root:nth-child(even)": {
      backgroundColor: "#33a2de",
      color: "rgba(255, 255, 255, 0.9)",
    },

    // Left & right summaries
    "& .MuiCard-root:nth-child(odd)": {
      backgroundColor: grey["50"],
    },

    // All points of the summaries
    "& li:nth-child(odd)": {
      backgroundColor: "#d7e9f961",
      boxShadow: "0 5px 10px -6px rgba(0, 0, 0, 0.1)",
    },
  },
}));

const Summaries = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h5" align="center" className={classes.headerDesc}>
          Une plateforme universelle aux usages multiples et évolutifs pour
          gérer toutes les relations
        </Typography>
        <Typography
          variant="overline"
          className={classes.headerIntro}
          color="textSecondary"
          align="center"
        >
          Toute relation professionnelle ou personnelle nécessite des échanges
          de données et de documents permanents
        </Typography>
        <Bar />
      </div>
      <div className={classes.summaries}>
        {summaries.map((s) => (
          <Summary key={s.title} summary={s} />
        ))}
      </div>
    </>
  );
};

export default Summaries;
