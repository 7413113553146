import React from "react";

// Components
import Image from "./image";
import Typography from "@material-ui/core/Typography";

// Styles
import { makeStyles } from "@material-ui/core/styles";

export type ProblemProps = {
  /** Problem to render */
  problem: Problem;
};

export type Problem = {
  /** Unique ID of the problem  */
  id: string | number;

  /** Name of the file used as the image */
  imageFilename: string;

  /** Title of the card. Also used as the `alt` of the image */
  title: string;

  /** Description of the card */
  description: React.ReactNode;

  /** Optional styles to apply to the image */
  imageStyles?: React.CSSProperties;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  image: {
    height: 100,
    width: 100,
  },
  title: {
    marginTop: theme.spacing(1),
  },
}));

export const Problem = ({ problem, ...props }: ProblemProps): JSX.Element => {
  const classes = useStyles();
  return (
    <div className={classes.root} {...props}>
      <Image
        style={problem.imageStyles}
        className={classes.image}
        alt={problem.title}
        filename={problem.imageFilename}
      />
      <Typography variant="h6" className={classes.title}>
        {problem.title}
      </Typography>
      <Typography align="center" color="textSecondary" variant="body1">
        {problem.description}
      </Typography>
    </div>
  );
};

export default Problem;
