import React from "react";

// Components
import Card, { CardProps } from "@material-ui/core/Card";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";

export type SummaryProps = {
  readonly summary: ISummary;
} & CardProps;

export type ISummary = {
  title: string;
  subtitle: string;
  points: ReadonlyArray<string>;
};

const Summary = ({ summary, ...props }: SummaryProps) => (
  <Card {...props}>
    <Typography variant="h5">{summary.title}</Typography>
    <Typography variant="subtitle1" color="inherit">
      {summary.subtitle}
    </Typography>
    <List>
      {summary.points.map((p, idx) => (
        <ListItem key={`${summary.title}-point-${idx}`}>
          <Typography variant="body1" align="center">
            {p}
          </Typography>
        </ListItem>
      ))}
    </List>
  </Card>
);

export default Summary;
