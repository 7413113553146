import React from "react";

// Components
import Grid, { GridProps } from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Image from "./image";
import Hidden from "@material-ui/core/Hidden";
import Bar from "./bar";

// Styles
import { StandardProps } from "@material-ui/core";
import { withStyles, createStyles } from "@material-ui/core/styles";
import { Link } from "gatsby";

export type PanelClassKey = "image";
export type PanelProps = {
  panel: IPanel;

  /**
   * If `true` the image will be on the right, otherwise it will be on the
   * left.
   *
   * @default false
   */
  imageRight?: boolean;

  hideButton?: boolean;
} & Partial<GridProps> &
  StandardProps<GridProps, PanelClassKey>;

export type IPanel = {
  /** Unique ID of the panel */
  id: string | number;

  /** Filename of the image */
  imageFilename: string;

  /** Title of the panel. Also used as the image `alt` prop */
  title: string;

  /** Subtitle of the panel */
  subtitle?: React.ReactNode;

  /** Body of the panel */
  body: React.ReactNode;

  /** Destination link of the "En savoir plus" button */
  learn_more_link: string;
};

const styles = createStyles({
  root: {
    minHeight: 500,
  },
  image: {
    width: "100%",
  },
});

const Panel = withStyles(styles)(function Panel({
  panel,
  hideButton,
  imageRight,
  classes,
  ...props
}: PanelProps) {
  return (
    <Grid
      direction={imageRight ? "row-reverse" : "row"}
      container
      spacing={3}
      className={classes?.root}
      {...props}
    >
      <Hidden xsDown>
        <Grid
          item={true}
          sm={6}
          container
          direction="column"
          justify="center"
          alignItems="flex-start"
          style={{ padding: 10 }}
        >
          <Image
            className={classes?.image}
            alt={panel.title}
            filename={panel.imageFilename}
          />
        </Grid>
      </Hidden>
      <Grid
        item={true}
        xs={12}
        sm={6}
        container
        direction="column"
        justify="center"
        alignItems="flex-start"
        style={{ paddingLeft: 40, paddingRight: 40 }}
      >
        <Typography variant="h4" color="inherit">
          {panel.title}
        </Typography>
        {panel.subtitle ? (
          <Typography variant="h6" color="textSecondary">
            {panel.subtitle}
          </Typography>
        ) : null}
        <Bar />
        <Typography
          variant="subtitle1"
          style={{ marginBottom: 30, marginTop: 20 }}
        >
          {panel.body}
        </Typography>
        {!hideButton ? (
          <Button
            component={Link}
            variant="outlined"
            color="primary"
            size="large"
            to={panel.learn_more_link}
          >
            En savoir plus
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
});

export default Panel;
